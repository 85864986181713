import http from '@/http-common'

const URL = '/api/auth'

class LoginService {
  getLoginUrl() {
    return http.get(`${URL}/get-login-url`)
  }
  sendLoginCode(code, state) {
    return http.post(`${URL}/callback`, {'code': code, 'state': state})
  }
  isLoggedIn(type) {
    //if type is provided, use type, else omit it
    if (type) {
      return http.get(`${URL}/check-login`, {params: {'type': type}})
    } else {
      return http.get(`${URL}/check-login`)
    }
  }
  logout() {
    return http.post(`${URL}/logout`)
  }
}

export default new LoginService()