<template>
  <h1 class="pb-3 logo">
    <span id="logo_1">nmt ma</span><span id="logo_2">!</span><span id="logo_3">nport</span>
  </h1>

  <ul id='menulist' class="list-unstyled flex-column mb-auto">
    <li>
      <button
        class="btn btn-toggle align-items-center rounded collapsed"
        data-bs-toggle="collapse"
        data-bs-target="#home-collapse"
        aria-expanded="false"
      >
        <i class="fa-light fa-house-blank"></i>{{$t('navigation.home')}}
      </button>
      <div class="collapse" id="home-collapse" data-bs-parent="#sidebarMenu">
        <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
          <li><router-link to="/" class="link-dark rounded">{{$tc('navigation.dashboard', 2)}}</router-link></li>
        </ul>
      </div>
    </li>
    <li v-if="isAdminOrUser">
      <button
        class="btn btn-toggle align-items-center rounded collapsed"
        data-bs-toggle="collapse"
        data-bs-target="#salesmarketing-collapse"
        aria-expanded="false"
      >
        <i class="fa-light fa-chart-line"></i>{{$t('navigation.sales_and_marketing')}}
      </button>
      <div class="collapse" id="salesmarketing-collapse" data-bs-parent="#sidebarMenu">
        <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
          <li><router-link to="/quotations" class="link-dark rounded">{{$tc('navigation.quotations', 2)}}</router-link></li>
          <li><router-link to="/contracts" class="link-dark rounded">{{$tc('navigation.contracts', 2)}}</router-link></li>
          <li><router-link to="/tariffs" class="link-dark rounded">{{$tc('navigation.tariffs', 2)}}</router-link></li>
          <li><router-link to="/surcharges" class="link-dark rounded">{{$tc('navigation.surcharges', 2)}}</router-link></li>
        </ul>
      </div>
    </li>
    <li>
      <button
        class="btn btn-toggle align-items-center rounded collapsed"
        data-bs-toggle="collapse"
        data-bs-target="#businessoperations-collapse"
        aria-expanded="false"
      >
        <i class="fa-light fa-ship"></i>{{$t('navigation.business_operations')}}
      </button>
      <div class="collapse" id="businessoperations-collapse" data-bs-parent="#sidebarMenu">
        <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
          <li v-if="isAdminOrUser"><router-link to="/voyages" class="link-dark rounded">{{$tc('navigation.voyages', 2)}}</router-link></li>
          <li v-if='!isProduction && isAdminOrUser'><router-link to="/voyageplanning" class="link-dark rounded">{{$t('navigation.voyage_planning')}}</router-link></li>
          <li v-if="isAdminOrUser || isCustomer"><router-link to="/jobs" class="link-dark rounded">{{$tc('navigation.jobs', 2)}}</router-link></li>
          <li v-if='!isProduction && (isAdminOrUser || isSurveyorOrPortOperator)'><router-link to="/portoperations" class="link-dark rounded">{{$t('navigation.port_operations')}}</router-link></li>
          <li v-if='!isProduction && isAdminOrUser'><router-link to="/freightlist" class="link-dark rounded">{{$t('navigation.freightlist')}}</router-link></li>
          <li v-if='!isProduction && isAdminOrUser'><router-link to="/exportcenter" class="link-dark rounded">{{$t('navigation.export_center')}}</router-link></li>
        </ul>
      </div>
    </li>
    <li v-if="isAdminOrUser">
      <button
        class="btn btn-toggle align-items-center rounded collapsed"
        data-bs-toggle="collapse"
        data-bs-target="#documentation-collapse"
        aria-expanded="false"
      >
        <i class="fa-light fa-book"></i>{{$t('navigation.documentation')}}
      </button>
      <div class="collapse" id="documentation-collapse" data-bs-parent="#sidebarMenu">
        <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
          <li><router-link to="billsoflading" class="link-dark rounded">{{$tc('navigation.bills_of_lading', 2)}}</router-link></li>
          <li v-if='!timer'><router-link to="exportprint" class="link-dark rounded">{{$t('navigation.export_&_print')}}</router-link></li>
          <li v-if='!timer'><router-link to="manifest_status" class="link-dark rounded">{{$t('navigation.manifest_status')}}</router-link></li>
            <li v-if='!isProduction || isAllowedForCompany || timer'><router-link to="mp_export" class="link-dark rounded">{{$t('navigation.mpexport')}} <template v-if="!timer">(new)</template></router-link></li>
          <li><router-link to="cfr_releases" class="link-dark rounded">{{$tc('navigation.cfr_releases', 2)}}</router-link></li>
        </ul>
      </div>
    </li>
     <li v-if="isAdminOrUser">
      <button
        class="btn btn-toggle align-items-center rounded collapsed"
        data-bs-toggle="collapse"
        data-bs-target="#accounting-collapse"
        aria-expanded="false"
      >
        <i class="fa-light fa-sack-dollar"></i>{{$t('navigation.accounting')}}
      </button>
      <div class="collapse" id="accounting-collapse" data-bs-parent="#sidebarMenu">
        <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
          <li v-if="canViewInvoices"><router-link to="invoices" class="link-dark rounded">{{$tc('navigation.invoices', 2)}}</router-link></li>
          <li><router-link to="shiploadinvoicing" class="link-dark rounded">{{$tc('navigation.shipload_invoicing', 2)}}</router-link></li>
          <li><router-link to="mainportprojects" class="link-dark rounded">{{$tc('navigation.mainport_projects', 2)}}</router-link></li>
          <li v-if="isAllowedForCompany || canViewInvoices"><router-link to="nmtcompanysettings" class="link-dark rounded">{{$tc('navigation.finance', 2)}}</router-link></li>
          <li><router-link to="paymentterms" class="link-dark rounded">{{$tc('navigation.payment_terms', 2)}}</router-link></li>
          <li v-if='!isProduction'><router-link to="readyinvoicing" class="link-dark rounded">{{$t('navigation.ready_for_invoicing')}}</router-link></li>
        </ul>
      </div>
    </li>
    <li v-if="isAdminOrUser">
      <button
        class="btn btn-toggle align-items-center rounded collapsed"
        data-bs-toggle="collapse"
        data-bs-target="#crm-collapse"
        aria-expanded="false"
      >
        <i class="fa-light fa-users"></i>{{$t('navigation.crm')}}
      </button>
      <div class="collapse" id="crm-collapse" data-bs-parent="#sidebarMenu">
        <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
          <li><router-link to="relations" class="link-dark rounded">{{$tc('navigation.relations', 2)}}</router-link></li>
          <li v-if='!isProduction'><router-link to="terminalholidays" class="link-dark rounded">{{$tc('navigation.terminalholiday', 2)}}</router-link></li>
          <li v-if="!isProduction && isAllowedForCompany" ><router-link to="worldcheck" class="link-dark rounded">{{$tc('navigation.world_check', 2)}}</router-link></li>
        </ul>
      </div>
    </li>

    <li v-if="isAllowedForCompany">
      <button
        class="btn btn-toggle align-items-center rounded collapsed"
        data-bs-toggle="collapse"
        data-bs-target="#masterdata-collapse"
        aria-expanded="false"
      >
        <i class="fa-light fa-database"></i>{{$t('navigation.masterdata')}}
      </button>
      <div class="collapse" id="masterdata-collapse" data-bs-parent="#sidebarMenu">
        <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
          <li><router-link to="/calculationunits" class="link-dark rounded">{{$tc('navigation.calculation_units', 2)}}</router-link></li>
          <li><router-link to="/colours" class="link-dark rounded">{{$tc('navigation.colours', 2)}}</router-link></li>
          <li><router-link to="/commodities" class="link-dark rounded">{{$tc('navigation.commodities', 2)}}</router-link></li>
          <li><router-link to="/commoditygroups" class="link-dark rounded">{{$tc('navigation.commoditygroups', 2)}}</router-link></li>
          <li v-if='!isProduction'><router-link to="/containertypes" class="link-dark rounded">{{$tc('navigation.container_types', 2)}}</router-link></li>
          <li><router-link to="/countries" class="link-dark rounded">{{$tc('navigation.countries', 2)}}</router-link></li>
          <li><router-link to="/currencies" class="link-dark rounded">{{$tc('navigation.currencies', 2)}}</router-link></li>
          <li><router-link to="/generalconfig" class="link-dark rounded">{{$t('navigation.general_configuration')}}</router-link></li>
          <li><router-link to="/generalcosts" class="link-dark rounded">{{$tc('navigation.general_costs', 2)}}</router-link></li>
          <li><router-link to="/hscodes" class="link-dark rounded">{{$tc('navigation.hs_codes', 2)}}</router-link></li>
          <li><router-link to="/makes" class="link-dark rounded">{{$tc('navigation.makes', 2)}}</router-link></li>
          <li><router-link to="/models" class="link-dark rounded">{{$tc('navigation.models', 2)}}</router-link></li>
          <li><router-link to="/ports" class="link-dark rounded">{{$tc('navigation.ports', 2)}}</router-link></li>
          <li v-if='!isProduction'><router-link to="/quotedescriptions" class="link-dark rounded">{{$tc('navigation.quotation_descriptions', 2)}}</router-link></li>
          <li><router-link to="/regions" class="link-dark rounded">{{$tc('navigation.regions', 2)}}</router-link></li>
          <li><router-link to="/surchargetype" class="link-dark rounded">{{$tc('navigation.surcharge_types', 2)}}</router-link></li>
          <li><router-link to="/locales" class="link-dark rounded">{{$tc('navigation.locales', 2)}}</router-link></li>
          <li><router-link to="/users" class="link-dark rounded">{{$tc('navigation.users', 2)}}</router-link></li>
          <li><router-link to="/vessels" class="link-dark rounded">{{$tc('navigation.vessels', 2)}}</router-link></li>
        </ul>
      </div>
    </li>

    <li v-if="!isProduction && isAllowedForCompany">
      <button
        class="btn btn-toggle align-items-center rounded collapsed"
        data-bs-toggle="collapse"
        data-bs-target="#tools-collapse"
        aria-expanded="false"
      >
        <i class="fa-light fa-tools"></i>{{$t('navigation.tools')}}
      </button>
      <div class="collapse" id="tools-collapse" data-bs-parent="#sidebarMenu">
        <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
          <li><router-link to="/manworldcheck" class="link-dark rounded">{{$tc('navigation.world_check_manual', 2)}}</router-link></li>
        </ul>
      </div>
    </li>
    
    <li v-if="isAllowedForCompany">
      <button
        class="btn btn-toggle align-items-center rounded collapsed"
        data-bs-toggle="collapse"
        data-bs-target="#logs-collapse"
        aria-expanded="false"
      >
        <i class="fa-light fa-history"></i>{{$t('navigation.logs')}}
      </button>
      <div class="collapse" id="logs-collapse" data-bs-parent="#sidebarMenu">
        <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
          <li><router-link to="/logs" class="link-dark rounded">{{$tc('navigation.logs', 2)}}</router-link></li>
          <li><router-link to="/ediLog" class="link-dark rounded">{{$tc('navigation.edilogs', 2)}}</router-link></li>
          <li><router-link to="/externalLog" class="link-dark rounded">{{$tc('navigation.externallog', 2)}}</router-link></li>
        </ul>
      </div>
    </li>
    
  </ul>

  
  <div class="dropdown">
    <hr />
    <a
      href="#"
      class="
        d-flex
        align-items-center
        link-dark
        text-decoration-none
        dropdown-toggle
        p-3
      "
      id="dropdownUser2"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <template v-if="getProfileImage">
        <img
          :src="getProfileImage"
          alt=""
          width="32"
          height="32"
          class="rounded-circle me-2"
        />
      </template>
      <template v-else>
        <div id='profileimage' class='rounded-circle me-2 bg-primary text-white text-center'>{{getInitials(getName)}}</div>
      </template>
      <strong>{{getName}}</strong>
    </a>
    <ul class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
      <li v-for="locale in $i18n.availableLocales.filter(row => row != $i18n.locale)" :key="`locale-${locale}`"><a class="dropdown-item" href="#" @click.prevent="changeLang(locale)">{{$t('locale_name', locale)}}</a></li>
      <!-- <li v-if='!isProduction'><a class="dropdown-item" href="#">New project...</a></li>
        <li><a class="dropdown-item" href="#">Settings</a></li>
        <li><a class="dropdown-item" href="#">Profile</a></li>
        <li><hr class="dropdown-divider"></li> -->
      <li><a class="dropdown-item" href="#" @click.prevent="logout">Sign out</a></li>
    </ul>
  </div>
</template>

<script>
import store from '@/store/user'
import router from '@/router'

import toggle from 'bootstrap'

export default {
  name: "navHeader",
  computed: {
    isProduction() {
        return process.env.VUE_APP_IS_PRODUCTION == "true";
    },
    getName () {
      return store.getters.getName
    },
    getProfileImage () {
      return store.getters.getProfileImage
    },
    isAdminOrUser () {
      return store.getters.isAdminOrUser ? 1 : 0
    },
    isAllowedForCompany () {
      return (store.getters.isAdmin ? 1 : 0) || ((store.getters.isUser ? 1 : 0) && store.getters.getCompanyHq)
    },
    isCustomer () {
      return store.getters.isCustomer ? 1 : 0
    },
    isSurveyorOrPortOperator () {
      return store.getters.isSurveyorOrPortOperator ? 1 : 0
    },
    canViewInvoices () {
      return store.getters.canViewInvoices ? 1 : 0
    },
    timer() {
      // Get the current date/time in UTC
      const now = new Date();
      const nowUtc = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds()));

      // Set the target date/time in UTC
      const targetUtc = new Date(Date.UTC(2024, 5, 17, 18, 0, 0, 0));

      // Compare the current UTC date/time with the target UTC date/time
      console.log("Timer", nowUtc.toUTCString(), targetUtc.toUTCString(), nowUtc > targetUtc);
      return nowUtc > targetUtc;
    }
  },
  methods: {
    logout() {
      store.dispatch('userLogout')
      router.push('/')
    },
    changeLang(locale) {
      this.$i18n.locale = locale;
    }
  },
  watch:{
    $route (to, from){
        if(from.name != undefined && from.name != "Callback") {
          return;
        }


        let active_items = document.querySelectorAll('#menulist .link-dark[href="' + to.href + '"]');
        if(active_items.length > 0) {
          let active_menu = active_items[0].parentNode.parentNode.parentNode;
          let active_menu_btn = active_menu.previousSibling;



          active_menu.classList.add("show");
          active_menu_btn.setAttribute("aria-expanded", "true");

          // active_menu_btn.click();
        }
    }
  }
};
</script>

