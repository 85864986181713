<template>
      <div v-if="isUpdateAvailable" id="updatealert" class="alert alert-warning" :class="(!isProduction ? 'test' : '')" role="alert" style="">
      <i class="fa-light fa-triangle-exclamation"></i> 
      An update is available
      <i class="fa-light fa-triangle-exclamation"></i><br>
      Please <a href='javascript:location.reload()'>refresh the page</a> to get access to the new content
    </div>
      

  <template v-if="$route.path.indexOf('quotationreply') != -1">
    <router-view :key="$route.path"/>
  </template>
  <template v-else>
    <template v-if="!isLoggedIn">
      <LoginComponent/>
    </template>
    <template v-if="isLoggedIn">

<div class="container-fluid">
        <nav id="sidebarMenu" class="app-nav-menu sidebar collapse">
          
            <navHeader/>
          
        </nav>
        
        <div v-if="!isProduction" id="testingalert" class="alert alert-warning" role="alert">
          <i class="fa-light fa-triangle-exclamation"></i>
          This is a testing server   -   For testing purposes only 
          <i class="fa-light fa-triangle-exclamation"></i>
        </div>
        <main class="app-main-content">
          <router-view :key="$route.path"/>
        </main>
    </div>
    </template>
  </template>
  
</template>


<script>
  import NavHeader from '@/components/NavHeader.vue'
  import LoginComponent from '@/components/auth/LoginComponent.vue';
  import http from '@/http-common'
  import store from '@/store/user'
  import dataStore from '@/store/data'
  import router from '@/router'
  import loginservice from '@/services/LoginService'
  import dataservice from '@/services/DataService'
  import * as Sentry from "@sentry/vue";
  import Echo from 'laravel-echo';
  import Pusher from 'pusher-js';

  export default {
    data() {
        return {
          updateAvailable: false
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                document.title = process.env.VUE_APP_TITLE +' | ' + to.meta.title ;
            }
        },
    },
    components: {
      NavHeader,
      LoginComponent
    },
    computed: {
      isProduction() {
        return process.env.VUE_APP_IS_PRODUCTION == "true";
      },
      isLoggedIn() {
        let isLoggedIn = store.getters.isLoggedIn;
        if(isLoggedIn){
          Sentry.setUser({id: store.getters.userId, username: store.getters.getName});
        }
        return isLoggedIn;
      },
      isUpdateAvailable() {
        return this.updateAvailable;
      }
    },
    methods :{
      rememberRoute(){
        sessionStorage.setItem("loginRedirect", window.location.href + '')
      },
      checkCredentials(refresh) {
        if (window.location.pathname != '/callback'){
          loginservice.isLoggedIn(refresh).then(response => {
            if (!response.data.approved) {
              sessionStorage.removeItem('authHeader');
              store.dispatch('userLogout')
              this.rememberRoute()
              if (window.location.pathname != '/') {
                router.push('/')
              }
            } else {
              
              sessionStorage.setItem("authHeader", store.getters.getAuthHeader);
              store.dispatch('userSessionLogin', response.data)
            }
          }).catch(error => {
            sessionStorage.removeItem('authHeader');
            store.dispatch('userLogout')
            this.rememberRoute()
            if (window.location.pathname != '/') {
              router.push('/')
            }
          })
        }
        
      }
    },
    mounted () {
      http.defaults['headers']['Authorization'] = store.getters.getAuthHeader
      var token = sessionStorage.getItem("authHeader");
      console.log("Session token", token);
      if(!token) {
        console.log("Checking token!");
        this.checkCredentials();
        console.log("Token checked", sessionStorage.getItem("authHeader"));
      }
      else{
        this.checkCredentials(true);
        dataservice.getData().then(response => {
          dataStore.dispatch('getData', response.data);
        }).catch(error => {
          console.log(error);
        });
      }

      String.prototype.toHSL = function() {
        var hash = 0,
          i, chr;
        if (this.length === 0) return "hsl(10 100% 40%)";
        for (i = 0; i < this.length; i++) {
          chr = this.charCodeAt(i);
          hash = ((hash << 5) - hash) + chr;
        }
        return `hsl(${hash % 100} 100% 40%)`;
      }

      window.savedFilterData = {};

      window.Pusher = Pusher;
      window.Echo = new Echo({
          broadcaster: 'pusher',
          key: process.env.VUE_APP_PUSHER_KEY,
          cluster: "eu",
          forceTLS: true
      });

      console.log('subscribing to `new-update`...', {
          $pusher: this.$pusher,
      })

      const channel = this.$pusher.subscribe('new-update');
      channel.bind('pusher:subscription_succeeded', () => console.log('subscription to "new-update" succeeded'))
      channel.bind('new-update', event => {
          console.log('new-update', event)
          this.updateAvailable = true;
          // this.voyageUpdate(event.voyage) 
      })
    }
  }

  http.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    if (error.response && error.response.status === 401) {
      store.dispatch('userLogout')
      this.rememberRoute()
      router.push('/')
    } else {
      Sentry.captureException(error);
    }
    return Promise.reject(error)
  })

</script>

<style>
@font-face {
	font-family: 'Segoe UI';
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	src: url("~@/assets/fonts/segoe-ui.ttf") format("truetype");
}

@font-face {
	font-family: 'Segoe UI Bold';
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	src: url("~@/assets/fonts/segoe-ui-bold.ttf") format("truetype");
}

@font-face {
	font-family: 'Inter No Period';
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	src: url("~@/assets/fonts/inter-comma-no-period.otf") format("opentype");
}

.number-format{
  font-family: 'Inter No Period' !important;
  font-weight: normal !important;
}

.text-ellipsis{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap
}
.fa-sort:not(.dragging){
  cursor: grab
}
.fa-sort.dragging{
  cursor: grabbing
}
.badge[data-status] {
    width: 100%;
    padding: .35em .25em
}
.badge[data-status="pending"], .badge[data-status="closed"]{
    background-color: #ffa000
}
.badge[data-status="accepted"], .badge[data-status="open"], .badge[data-status="sent"]{
    background-color: #43a047
}
.badge[data-status="declined"], .badge[data-status="cancelled"]{
    background-color: #f4511e
}
.badge[data-status="expired"], .badge[data-status="archived"], .badge[data-status="paid"]{
    background-color: #666
}
.badge[data-status="approved"]{
    background-color: #0d6efd
}
.badge[data-status="draft"]{
    background-color: var(--bs-primary)
}
.license-plate{
  text-transform: uppercase
}
textarea{
  font-size: 0.875rem !important
}
td.center-td{
    text-align: center
}
th.center-td > div{
    justify-content: center
}
.p-datatable-table tr:not(.p-datatable-row-expansion):hover{
    background-color: #f8f9fa;
    cursor: pointer
}
.p-datatable-row-expansion > td { 
    padding-top: 0px;
    border-bottom: 0px;
}
td.office-column::before{
    content: 'NMT'
}

.modal-in-modal{
  z-index: 1060 !important;
  background-color: rgba(0,0,0,0.35)
}
.modal-in-modal .modal-content{
  z-index: 1062 !important
}
dialog::backdrop{
  background: rgba(1, 1, 1, 0.33)
}

pre{
  font-family: 'Inter', sans-serif !important;
}

.pi-sort-alt{
  display: none !important
}

.dp__input.is-invalid{
  border-color: red !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

main .nav-tabs{
    border: none
}
main .nav-link {
    font-weight: bold
}
main .nav-link.active {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #0d6efd !important;
    border-radius: 0px;
    color: black !important
}
main .colour-black {
    color: #212529 !important
}
main .nav-link:not(.active) {
    border: none;
    color: gray
}

.fake-link{
    color: #0d6efd !important;
    cursor: pointer;
    text-decoration: underline;
}
.fake-link:hover{
  opacity: 80%
}

#hide-surcharge-slider{
    width: 22% !important
}
#hide-surcharge-slider > div, #downloadshowpdfbutton > div{
    margin: unset !important;
}
#hide-surcharge-slider > div > div{
    padding-left: 0 !important;
    padding-right: 0 !important;
}
#hide-surcharge-slider > div > label{
    padding-right: 0 !important;
}
</style>

<style scoped>
#testingalert {
  width: auto;
  position: absolute;
  left: calc(50% + 130px);
  top: 10px;
  transform: translateX(-50%);
}

#updatealert {
    width: auto;

    position: absolute;
    left: calc(50% + 130px);
    top: 10px;
    transform: translateX(-50%);
    z-index: 10000;
    text-align: center;
}

#updatealert.test {
  top: 70px;
}

@media (min-width: 768px) {
  .app-nav-menu {
    display: flex !important;
    width: 260px;
  }
  .app-main-content {
    display: flex !important;
    width: calc(100% - 276px);
    margin-left: 265px
  }
}
@media (max-width: 768px) {
  .app-nav-menu {
    display: none;
  }
  .app-main-content {
    display: flex !important;
    width: 100%
  }
}
</style>
