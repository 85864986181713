import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    condition_types: [],
    container_types: [],
    countries: [],
    customs_types: [],
    invoice_statuses: [],
    measurement_conditionals: [],
    region_types: [],
    relation_types: [],
    unit_statuses: [],
    vat_types: [],
    voyage_statuses: [],
    user_groups: []
  },
  plugins: [createPersistedState({
    "key": "data"
  })],
  mutations: {
    GET_DATA(state, userData) {
      state.condition_types = userData.conditions
      state.container_types = userData.container_types
      state.countries = userData.countries
      state.customs_types = userData.customs_types
      state.invoice_statuses = userData.invoice_statuses
      state.measurement_conditionals = userData.measurement_conditionals
      state.region_types = userData.region_types
      state.relation_types = userData.relation_types
      state.unit_statuses = userData.unit_statuses
      state.vat_types = userData.vat_types
      state.voyage_statuses = userData.voyage_statuses,
      state.user_groups = userData.user_groups
    },
  },
  actions: {
    getData({getters, commit}, response) {
      commit('GET_DATA', response)
    },
  },
  modules: {
  },
  getters: {
    getConditions: state => {
      return state.condition_types
    },
    getContainerTypes: state => {
      return state.container_types
    },
    getCountryNames: state => {
      return state.countries
    },
    getCustomTypes: state => {
      return state.customs_types
    },
    getInvoiceStatuses: state => {
      return state.invoice_statuses
    },
    getMeasurementConditionals: state => {
      return state.measurement_conditionals
    },
    getRegionTypes: state => {
      return state.region_types
    },
    getRelationTypes: state => {
      return state.relation_types
    },
    getUnitStatuses: state => {
      return state.unit_statuses
    },
    getVatTypes: state => {
      return state.vat_types
    },
    getVoyageStatuses: state => {
      return state.voyage_statuses
    },
    getUserGroups: state => {
      return state.user_groups
    }
  }
})
