import { createApp } from 'vue'
import { ObjectUtils } from 'primevue/utils';
import App from './App.vue'
import router from './router'
import store from './store/user'
import dataStore from './store/data'
import moment from 'moment'
import vueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css'
import PrimeVue from 'primevue/config'
import "bootstrap/dist/css/bootstrap.min.css"
import './assets/dark-blue.css';
import { createI18n } from 'vue-i18n'
import messages from './locales'
import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";

import FormItem from '@/components/formitem/FormItem.vue'
// @ts-ignore
import PusherPlugin from './plugins/pusher'


// Type-define 'en' as the master schema for translations
type MessageSchema = typeof messages.en

const i18n = createI18n<[MessageSchema], 'en' | 'nl'>({
  locale: 'en',
  messages: messages
})


const app = createApp(App).use(store).use(dataStore).use(router).use(vueToast).use(PrimeVue).use(i18n).use(PusherPlugin, {
    cluster: 'eu'
}).mixin({
//    const app = createApp(App).use(store).use(router).use(vueToast).use(PrimeVue).use(i18n).mixin({
    methods: {
        formatDate (value: string, type: string) {
            if(value === null) return '';
            const definedMoment = moment(value).locale(this.$i18n.locale)
            if (type == 'dateTime') {
                return definedMoment.format('DD-MM-YYYY HH:mm:ss')
            } else if (type == 'dateTimeNoSeconds') {
                return definedMoment.format('DD-MM-YYYY HH:mm')
            } else if (type == 'dateTimeAmerican') {
                return definedMoment.format('MMM D, HH:mm')
            } else if (type == 'fromNow') {
                return definedMoment.fromNow()
            } else if (type == 'isoDate') {
                return definedMoment.format('YYYY-MM-DD')
            } else if (type == 'shortDate') {
                return definedMoment.format('DD-MM-YYYY')
            } else if (type == 'shortDateWithDayOfWeek') {
                return definedMoment.format('ddd MMM D')
            } else if (type == 'shortDateAmerican') {
                return definedMoment.format('MMM D YYYY').replace(' ' + (new Date().getFullYear()), '')
            } else if (type == 'tinyDateAmerican') {
                return definedMoment.format('MMM D')
            } else if (type == 'longDate') {
                return definedMoment.format('D MMMM YYYY')
            } else if (type == 'longDateNoYear') {
                return definedMoment.format('D MMMM')
            } else if (type == 'longDateAmerican') {
                return definedMoment.format('MMM D, YYYY')
            }  else if (type == 'tinyDate') {
                return definedMoment.format('DD-MM')
            } else if (type == 'dateTimeHistory') {
                if(definedMoment.isAfter(moment().subtract(1, 'day').endOf('day')))
                    return this.$i18n.t('time.today') + ' ' + definedMoment.format('HH:mm:ss')
                if(definedMoment.isAfter(moment().subtract(2, 'day').endOf('day')))
                    return this.$i18n.t('time.yesterday') + ' ' + definedMoment.format('HH:mm:ss')
                return definedMoment.format('MMMM D HH:mm:ss')
            } 

            return definedMoment.format('YYYY-MM-DD')
        },
        formatCurrency (value: number | string, fractions = '') {
            const nvalue = typeof value == 'string' ? Number(value.replace(',', '.')) : value;
            const setFractions = fractions ? Number(fractions) : 2;
            return new Intl.NumberFormat('en-NL', {minimumFractionDigits: 2, maximumFractionDigits: setFractions}).format(nvalue);
        },
        formatNumber (value: number | string) {
            const nvalue = typeof value == 'string' ? Number(value.replace(',', '.')) : value;
            return new Intl.NumberFormat('en-NL', {minimumFractionDigits: 0, maximumFractionDigits: 0}).format(nvalue);
        },
        formatModuleName(name: string, t: any, isPlural = true){
            const formattedName = 'navigation.' + name.toLowerCase().replaceAll('_','').replaceAll(' ','_');
            return t.tc(formattedName, isPlural ? 2 : 1);
        },
        getInitials(storeName: string) {
          const name = storeName.replace("(IT Support) ", "");
    
          const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
    
          const initialsArray = Array.from(name.matchAll(rgx)) || [];
          let initials = '';
    
          const first = initialsArray.shift();
          const second = initialsArray.shift();
    
          if (first && second) {
            initials = first[1] + second[1];
          } else {
            initials = '?';
          }
    
          return initials;
        },
        async uploadFile(e : any, field: any, column: string, multiple = false, extra_field = '', extra_value = null){
            async function blobToBase64(blob: Blob) {
                return new Promise((resolve, _) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(typeof reader.result == 'string' ? reader.result.substr(reader.result.indexOf(',')+1) : '');
                    reader.readAsDataURL(blob);
                });
            }
            if (e.target.files) {
                const files = e.target.files;
                field[column] = [];
                
                for (let i = 0; i < files.length; i++) {
                    const file = files[i]

                    const fileSize = Math.ceil(file.size / 1024) + ' kb';
                    const fileType = file.type.split('/')[1];
                    if(file.name.match(/\.csv$|\.doc?$|\.docx?$|\.xls?$|\.xls(x|m)$|\.pdf?$|\.eml?$|\.msg?$|\.png?$|\.gif?$|\.jpg?$|\.jpeg?$|\.bmp?$|\.tif?$|\.tiff$/)){
                        const newObj:any = {
                            'type': fileType,
                            'name': file.name.replace(/[^a-z0-9.]/gi, '_'),
                            'size': fileSize,
                            'date': moment(),
                            'user': store.getters.getUser,
                            'blob': await blobToBase64(file),
                            'url': window.URL.createObjectURL(file)
                        };
                        if(extra_field){
                            newObj[extra_field] = extra_value;
                        }
                        field[column].push(newObj);
                        if(!multiple) break;
                    }
                }
            }
            e.target.value = "";
        },
        checkIfLicensePlateIsRequired(pols: Array<any>, pods: Array<any>, ports: Array<any>){
            let isLicensePlateRequired = false;
            for(const port of pols){
                const portIdField = port.port_id !== undefined ? 'port_id' : 'id';
                const portData = ports.find(p => p.id == port[portIdField]);
                if(!portData) continue;
                if(portData.license_plate_required_export){
                    isLicensePlateRequired = true;
                    break;
                }
            }
            if(isLicensePlateRequired) return true;
            for(const port of pods){
                const portIdField = port.port_id !== undefined ? 'port_id' : 'id';
                const portData = ports.find(p => p.id == port[portIdField]);
                if(!portData) continue;
                if(portData.license_plate_required_import){
                    isLicensePlateRequired = true;
                    break;
                }
            }
            return isLicensePlateRequired;
        },
        licensePlateAlterInput(e: KeyboardEvent, allowSpaces = false){
            if(e.key.length > 1){
                return;
            }
            if(e.ctrlKey || e.metaKey){
                return;
            }
            const regex = allowSpaces ? /[^0-9a-zA-Z ]/ : /[^0-9a-zA-Z]/;
            if(regex.test(e.key)){
                e.preventDefault();
                return;
            }
        },
        toUpper(e : HTMLInputElement | HTMLTextAreaElement, chassisObject: any){
            if(e.value)
                e.value = e.value.toUpperCase();
            if(chassisObject){
                e.value = e.value.replaceAll('O', '0');
                chassisObject.chassis_number = e.value;
            }
        }, 
        focusFirstInputField(e: Event){
            const id = ((e.target as HTMLElement).getAttribute('data-bs-target') as string);
            let elToFocus = document.querySelector(id + ' input');
            if(elToFocus){ 
                window.setTimeout(() => {
                    (elToFocus as HTMLInputElement).focus();
                }, 600);
            }
            else{
                elToFocus = document.querySelector(id + ' textarea');
                if(elToFocus){ 
                    window.setTimeout(() => {
                        (elToFocus as HTMLTextAreaElement).focus();
                    }, 600);
                }
            }
        },
        preventNonNumbers(e: KeyboardEvent, allowCommas = true, allowNegative = false){
            if(['Backspace', 'Left', 'ArrowLeft', 'Right', 'ArrowRight', 'Tab', 'Home', 'End'].includes(e.key)){
                return;
            }
            if(e.ctrlKey || e.metaKey){
                return;
            }
            let regex = /[^0-9]/;
            if(allowCommas && allowNegative) regex = /[^.\-0-9]/;
            else if(allowNegative) regex = /[^\-0-9]/;
            else if(allowCommas) regex = /[^.0-9]/;
            if(regex.test(e.key)){
                e.preventDefault();
            }
            if(allowCommas && e.key === ','){
                (e.target as (HTMLInputElement)).value += '.';
            }
        },
        safeFind(array : Array<any>, id : any, value : any, field : any){
            const match = array.find(c => c[id] == value);
            return match ? ObjectUtils.resolveFieldData(match, field) : null;
        },
        capitalize(str: string){
            if(!str) return '';
            const arr = str.split(' ');
            const result = [] as Array<string>;
            arr.forEach(el => result.push(el[0] + el.substring(1).toLowerCase()) );
            return result.join(' ');
        },
        findDateForPortTransshipment(transferports: Array<any>, ports: Array<any>, portIds: Array<number>, podId: number){
            try{
                let portOfDestination = null;
                const arrivalPort = ports[0];
                // let i = ports.findIndex(port => portIds.includes(port.id) );
                // if(i == -1){
                //     return {};
                // }
                if(!arrivalPort){
                    return {};
                }
                const code_1 = arrivalPort.port.code;
                const date_1 = arrivalPort.date_departure ? arrivalPort.date_departure : arrivalPort.date_arrival;
                const id_1 = arrivalPort.port_id;
                const tdate_1 = arrivalPort.date_departure ? arrivalPort.date_departure : arrivalPort.date_arrival;
                let id_2 = null;
                let code_2 = null
                let date_2 = null;
                let tdate_2 = null;
                let tdays = 0;
                let d_departure = tdate_1;
                let transferCode = null;
                // let searching = true;
                // i++;
                // while(searching){
                    if(!arrivalPort){
                        return;
                    }

                    if (transferports[0]) {
                        d_departure = transferports[0].pivot.date_departure ? transferports[0].pivot.date_departure : transferports[0].pivot.date_arrival;
                        transferCode = transferports[0].code;
                    }
                    let i = 0;
                    // console.log('Number(new Date(d_departure))')
                    // console.log(Number(new Date(d_departure)))
                    // console.log('d_departure')
                    // console.log(d_departure)
                    // console.log('Number(new Date(date_1))')
                    // console.log(Number(new Date(date_1)))
                    // console.log('date_1')
                    // console.log(date_1)
                    portOfDestination = transferports.find(tport => tport.id == podId);
                    while (Number(new Date(d_departure)) < Number(new Date(date_1)) && i <100) {
                        i++
                        if (transferports[i]) {
                            d_departure = transferports[i].pivot.date_departure ? transferports[i].pivot.date_departure : transferports[i].pivot.date_arrival;
                            transferCode = transferports[i].code
                        }
                        if (transferports[i].id == podId) {
                            portOfDestination = transferports[i]
                        }
                    }

                    if (portOfDestination) {
                        tdate_2 = portOfDestination.pivot.date_departure ? portOfDestination.pivot.date_departure : portOfDestination.pivot.date_arrival;
                        id_2 = portOfDestination.id;
                        date_2 = portOfDestination.pivot.date_arrival;
                        code_2 = portOfDestination.code
                    }
                    tdays = Math.round(Math.abs(Number(new Date(d_departure)) - Number(new Date(date_1))) / (1000 * 60 * 60 * 24));

                    // i++;
                // }
                // const portId = podId;
                // console.log("port.port_id")
                // console.log(port.port_id)
                // console.log("transferports")
                // console.log(transferports)
                return {date_1: date_1, code_1: code_1, transfer: transferCode, date_2: date_2, code_2: code_2, pod_id: id_2, pol_id: id_1,
                        t_days: tdays, transfer_arr: tdate_1, transfer_dep: d_departure, trn_id: id_2};
            }
            catch(e){
                console.log(e);
                return {};
            }
        },
        getFileIcon(ext: string){
            switch(ext){
                case "Commercial Invoice":
                    return "file-invoice-dollar";
                case "Packing list":
                    return "file-lines";
                case "Pictures":
                    return "images";
                case "Custom doc":
                    return "file-invoice";
                case "Certificates":
                    return "file-certificate";
                case "Other":
                    return "file";
                case "doc":
                case "docx":
                case "txt":
                case "vnd.ms-word":
                case "vnd.openxmlformats-officedocument.wordprocessingml.document":
                    return "file-text";
                case "csv":
                case "xls":
                case "xlsm":
                case "xlsx":
                case "vnd.ms-excel":
                case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                    return "table";
                case "pdf":
                    return "file-pdf";
                case "eml":
                case "msg":
                    return "envelope";
            }
            return "image";
        },
        sortTransshipments(type: number, a: any, b: any){
            if(type == 1){
                return a.pol_date > b.pol_date;
            }
            if(type == 2){
                return a.transit_time > b.transit_time;
            }
            if(type == 3){
                return a.trans_time > b.trans_time;
            }
        },
        async toastError(error: any) {
            let message = '';

            if(error.response && error.response.data && error.response.data.message) {
                message = error.response.data.message;

                // if(Object.keys(error.response.data.errors).length) {
                    message += "<br>"
                    let errorkey = null;
                    for(errorkey in error.response.data.errors) {
                        message += " - " + error.response.data.errors[errorkey] + "<br>";
                    }
                    
                // }
            } else if(error.response && error.response.data && error.response.data instanceof Blob) {
                message = await error.response.data.text();
                const messageJSON = JSON.parse(message);
                message = (messageJSON && messageJSON.message) ? messageJSON.message : 'Unknown error occurred';
            } else {
                message = typeof error === 'string' ? error : 'Unknown error occurred';
            }

            this.$toast.error(message)
        },
        triggerDownload(responseData: any, name: string){
            const url = window.URL.createObjectURL(new Blob([responseData]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", name);
            document.body.appendChild(link);
            link.click();
        }
    }
});


app.component("FormItem", FormItem);

app.config.errorHandler = (err, instance, info) => {
    console.log("err", err);
    console.log("instance", instance);
    console.log("info", info);

}


Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DNS,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            // Additional SDK configuration goes in here, for example:
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost:8080", "https://nmtmainport.com.netvibestest.net/", "https://nmtmainport.com/", /^\//],
  
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    trackComponents: true,
    logErrors: true
  });

app.mount('#app');
