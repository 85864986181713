<template>
<div class='background text-center'>
  <div class='text-center'>
      <main class="form-signin">
      <form>
          <img class="mb-4" src="https://www.nmtshipping.com/img/nmt_logo.png" alt="NMT Shipping logo" width="210" height="100">
          <h1 class="h3 mb-3 fw-normal">Please sign in</h1>

          <div v-if="this.errormessage" class="alert alert-danger" role="alert">
            {{this.errormessage}}
          </div>

          <img v-if="!this.processing" id='loginbutton' src='/img/ms-symbollockup_signin_light.svg' @click="login">    
          <template v-if="this.processing"><i class="fas fa-spinner fa-spin"></i> please wait...</template>
      </form>
      </main>
  </div>
</div>
    
</template>

<script>
  import loginservice from '@/services/LoginService'
  import store from '@/store/user'
  import http from '@/http-common'

    export default {
        name: 'LoginComponent',
        data() {
            return {
                processing: false,
                errormessage: null
            }
        },
        methods: {
             login() {
                this.processing = true;
                loginservice.getLoginUrl().then(response => {
                  location.assign(response.data.url);
                  this.processing = false;
                }).catch(error => {
                  this.processing = false;
                  console.log('error', error) // create notification-toaster for user error
                })
            },
             handleCallback(code, state) {
                this.processing = true;
                loginservice.sendLoginCode(code, state).then(response => {
                  this.processing = false;
                  if (!response.data.approved) {
                    this.errormessage = "Your user account does not have access to NMT Mainport.";
                    console.log('User not approved.', response) // create notification-toaster for user error
                  } else {
                    store.dispatch('userLogin', response.data)
                    http.defaults['headers']['Authorization'] = store.getters.getAuthHeader
                    sessionStorage.setItem("authHeader", store.getters.getAuthHeader);
                    const loginRedirect = sessionStorage.getItem("loginRedirect");
                    if(loginRedirect){
                      window.location = loginRedirect
                    }
                    else{
                      this.$router.push({
                        name: "Home"              
                      })
                    }
                    sessionStorage.removeItem("loginRedirect")
                  }
                }).catch(error => {
                  this.processing = false;
                  console.log('error', error) // create notification-toaster for user error
                })
            },
        },
        mounted () {
          const urlParams = new URLSearchParams(window.location.search);
          const code = urlParams.get('code');
          const state = urlParams.get('state');

          // const sessionState = urlParams.get('session_state');
          if (code && state) {
            this.handleCallback(code, state)
          }
        }
    }
</script>

<style scoped>
.background {
  min-height: 100vh;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-image: url(/img/nmt-shipping-homepage-2000x1240.jpg);
  background-size: cover;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 55px;
  margin: auto;
  
  background-color: #f5f5f5;

  border-radius: 25px;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

#loginbutton {
  cursor: pointer;
}
</style>
