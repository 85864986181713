import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    meta:{
      title: 'Home | Dashboard'
    },
    component: () => import('../views/Home.vue')
  },
  {
    path: '/callback',
    name: 'Callback',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/users/:id?',
    name: 'Users',
    meta:{
      title: 'MD | Users'
    },
    component: () => import('../views/user/User.vue')
  },
  // Regions
  {
    path: '/regions/:id?',
    name: 'Regions',
    meta:{
      title: 'MD | Regions'
    },
    component: () => import('../views/region/Region.vue')
  },
  // Countries
  {
    path: '/countries/:id?',
    name: 'Countries',
    meta:{
      title: 'MD | Countries'
    },
    component: () => import('../views/country/Country.vue')
  },

  // TerminalHoliday
  {
    path: '/terminalholidays/:id?',
    name: 'TerminalHoliday',
    meta:{
      title: 'CRM | TerminalHoliday'
    },
    component: () => import('../views/terminalholiday/TerminalHoliday.vue')
  },

// port
{
  path: '/ports/:id?',
  name: 'ports',
  meta:{
    title: 'MD | Ports'
  },
  component: () => import('../views/port/Port.vue')
},
// terminals
{
  path: '/terminals/:id?',
  name: 'terminals',
  meta:{
    title: 'MD | Terminals'
  },
  component: () => import('../views/terminal/Terminal.vue')
},

// Vessels
{
  path: '/vessels/:id?',
  name: 'vessels',
  meta:{
    title: 'MD | Vessels'
  },
  component: () => import('../views/vessel/Vessel.vue')
},

// Voyages
{
  path: '/voyages/:id?',
  name: 'voyages',
  meta:{
    title: 'BO | Voyages'
  },
  component: () => import('../views/voyage/Voyage.vue')
},
{
  path: '/voyageplanning/:id?',
  name: 'Voyage Planning',
  meta:{
    title: 'BO | Voyages planning'
  },
  component: () => import('../views/voyageplanner/VoyagePlanning.vue')
},

// VoyagesStatusses
{
  path: '/mp_export/:id?',
  name: 'mpexport',
  meta:{
    title: 'Status & Print'
  },
  component: () => import('../views/exportprint/MPExport.vue')
},

// relations
{
  path: '/relations/:type', 
  name: 'Customers',
  meta:{
    title: 'CRM | Customers'
  },
  component: () => import('../views/relation/Relation.vue')
},
{
  path: '/relations/:id?', 
  name: 'Relations',
  meta:{
    title: 'CRM | Relations'
  },
  component: () => import('../views/relation/Relation.vue')
},
// Commoditiegroups
{
  path: '/commoditygroups/:id?',
  name: 'Commoditygroups',
  meta:{
    title: 'MD | Commoditygroups'
  },
  component: () => import('../views/commoditygroup/Commoditygroup.vue')
},
// Commodities
{
  path: '/commodities/:id?',
  name: 'Commodities',
  meta:{
    title: 'MD | Commodities'
  },
  component: () => import('../views/commodity/Commodity.vue')
},
// General Costs
{
  path: '/generalcosts/:id?',
  name: 'General costs',
  meta:{
    title: 'MD | General costs'
  },
  component: () => import('../views/generalcost/Generalcost.vue')
},
// Currencies
{
  path: '/currencies/:id?',
  name: 'Currencies',
  meta:{
    title: 'MD | Currencies'
  },
  component: () => import('../views/currency/Currency.vue')
},
// Contracts
{
  path: '/contracts/:id?',
  name: 'Contracts',
  meta:{
    title: 'BO | Contracts'
  },
  component: () => import('../views/contract/Contract.vue')
},
// Contracts
{
  path: '/calculationunits/:id?',
  name: 'Calculation units',
  meta:{
    title: 'MD | Calculation units'
  },
  component: () => import('../views/calculationunit/Calculationunit.vue')
}
,
// Quotations
{
  path: '/quotations/:id?',
  name: 'Quotations',
  meta:{
    title: 'MD | Quotations'
  },
  component: () => import('../views/quotation/Quotation.vue')
},
//Tarriflevel
{
  path: '/tarifflevels/:id?',
  name: 'Tariff levels',
  meta:{
    title: 'S&M | Tariff levels'
  },
  component: () => import('../views/tarifflevel/Tarifflevel.vue')
}
,
//Tariffs
{
  path: '/tariffs/:id?',
  name: 'Tariffs',
  meta:{
    title: 'S&M | Tariffs'
  },
  component: () => import('../views/tariff/Tariff.vue')
}
,
//Locales
{
  path: '/locales',
  name: 'Locales',
  meta:{
    title: 'MD | Locales'
  },
  component: () => import('../views/locales/Locales.vue')
},
//Surcharges
{
  path: '/surcharges/:id?',
  name: 'Surcharges',
  meta:{
    title: 'S&M | Surcharges'
  },
  component: () => import('../views/surcharge/Surcharge.vue')
},
//Surcharge Type
{
  path: '/surchargetype/:id?',
  name: 'Surcharge Types',
  meta:{
    title: 'MD | Surcharge Types'
  },
  component: () => import('../views/surcharge/SurchargeType.vue')
}
,
//Jobs
{
  path: '/jobs/:id?',
  name: 'Jobs',
  meta:{
    title: 'Jobs'
  },
  component: () => import('../views/jobs/Jobs.vue')
},
//Quotation approve
{
  path: '/quotationreply/:quotationId',
  name: 'Quotation reply',
  meta:{
    title: 'Quotation reply'
  },
  component: () => import('../views/quotation/Quotationreply.vue')
},
//Make
{
  path: '/makes/:id?',
  name: 'Makes',
  meta:{
    title: 'Makes'
  },
  component: () => import('../views/make/Make.vue')
},
//Model
{
  path: '/models/:id?',
  name: 'Models',
  meta:{
    title: 'Models'
  },
  component: () => import('../views/model/Model.vue')
},
//HS code
{
  path: '/hscodes/:id?',
  name: 'HS codes',
  meta:{
    title: 'HS codes'
  },
  component: () => import('../views/hscode/Hscode.vue')
},
//Colour
{
  path: '/colours/:id?',
  name: 'Colours',
  meta:{
    title: 'Colours'
  },
  component: () => import('../views/colour/Colour.vue')
},
//Logs
{
  path: '/logs',
  name: 'logs',
  meta:{
    title: 'logs'
  },
  component: () => import('../views/log/Log.vue')
},
{
  path: '/externalLog',
  name: 'externalLog',
  meta:{
    title: 'externalLog'
  },
  component: () => import('../views/log/ExternalLog.vue')
},
{
  path: '/ediLog',
  name: 'ediLog',
  meta:{
    title: 'ediLog'
  },
  component: () => import('../views/log/EdiLog.vue')
},
//Additional Quote Description Templates
{
  path: '/quotedescriptions/:id?',
  name: 'Quotation descriptions',
  meta:{
    title: 'Quotation descriptions'
  },
  component: () => import('../views/quotation/QuotationDescription.vue')
},
//Invoices
{
  path: '/invoices/:id?',
  name: 'Invoices',
  meta:{
    title: 'Invoices'
  },
  component: () => import('../views/invoice/Invoice.vue')
},
//Finances
{
  path: '/nmtcompanysettings/:id?',
  name: 'Finance',
  meta:{
    title: 'Finance'
  },
  component: () => import('../views/finance/Finance.vue')
},
//World-Check
{
  path: '/worldcheck/:id?',
  name: 'World Check',
  meta:{
    title: 'World Check'
  },
  component: () => import('../views/relation/Worldcheck.vue')
},
//World-Check
{
  path: '/portoperations/:id?',
  name: 'Port Operations',
  meta:{
    title: 'Port Operations'
  },
  component: () => import('../views/portoperations/PortOperations.vue')
},
//Container types
{
  path: '/containertypes/:id?',
  name: 'Container Types',
  meta:{
    title: 'Container Types'
  },
  component: () => import('../views/containertype/ContainerType.vue')
},
//Ready For Invoicing
{
  path: '/readyinvoicing/:id?',
  name: 'Ready For Invoicing',
  meta:{
    title: 'Ready For Invoicing'
  },
  component: () => import('../views/invoice/ReadyForInvoicing.vue')
},
//Payment Terms
{
  path: '/paymentterms/:id?',
  name: 'Payment Terms',
  meta:{
    title: 'Payment Terms'
  },
  component: () => import('../views/paymentterm/PaymentTerm.vue')
},
//General Configuration
{
  path: '/generalconfig',
  name: 'General Configuration',
  meta:{
    title: 'General Configuration'
  },
  component: () => import('../views/generalconfig/GeneralConfig.vue')
},
{
  path: '/freightlist/:id?',
  name: 'Freightlist',
  meta:{
    title: 'Freightlist'
  },
  component: () => import('../views/freightlist/FreightList.vue')
},
{
  path: '/mainportprojects',
  name: 'Mainport Projects',
  meta:{
    title: 'Mainport Projects'
  },
  component: () => import('../views/legacy/MainportProjects.vue')
},
{
  path: '/shiploadinvoicing',
  name: 'Shipload Invoicing',
  meta:{
    title: 'Shipload Invoicing'
  },
  component: () => import('../views/legacy/ShiploadInvoicing.vue')
},
{
  path: '/exportprint',
  name: 'Status & Print',
  meta:{
    title: 'Status & Print'
  },
  component: () => import('../views/exportprint/ExportPrint.vue')
},
{
  path: '/callback/accounting',
  name: 'export callback',
  meta:{
    title: 'export callback'
  },
  component: () => import('../views/callback/Accounting.vue')
},
{
  path: '/billsoflading',
  name: 'Bills of Lading',
  meta:{
    title: 'Bills of Lading'
  },
  component: () => import('../views/jobs/BillsOfLadingNew.vue')
},
{
  path: '/manworldcheck',
  name: 'Manual World Check',
  meta:{
    title: 'Manual World Check'
  },
  component: () => import('../views/worldcheck/ManualWorldCheck.vue')
},
{
  path: '/manifest_status',
  name: 'Manifest Status',
  meta:{
    title: 'Manifest Status'
  },
  component: () => import('../views/manifeststatus/ManifestStatus.vue')
},
{
  path: '/cfr_releases',
  name: 'CFR Releases',
  meta:{
    title: 'CFR Releases'
  },
  component: () => import('../views/cfr/CFRReleases.vue')
},
{
  path: '/exportcenter',
  name: 'Export center',
  meta:{
    title: 'Export center'
  },
  component: () => import('../views/export/ExportCenter.vue')
}

]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    return {top: savedPosition?.top, left: 0}
  }
})

export default router
